.no-scroll {
  overflow: hidden;
}
html,body,.App{
  width: 100%;
}
.container{
  width: 80%;
  padding: 0 20px;
  margin:0 auto;
}
section{

}
section.sec_01{
  position: relative;
  /* background: url(/public/img/bg1.jpg) 50% 50% /100% auto no-repeat; */
  background: #222;
  color: #FFF;
  user-select: none;
  height: 100vh;
  min-height: 400px;
}
section.sec_01 h2{
text-shadow: 0 0 8px rgba(0,0,0,0.5);
font-weight: 100;
}
section.sec_01::before{
  content:"";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.1);
  z-index: 1;
}
section.sec_02{
  background: #f1f1f1;
  position: relative;
  user-select:none;
}
section.sec_02::before{
  top: 280px;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(0,0,0,0.3);
}
section.sec_03{
  position: relative;
  /* background: url(/public/img/bg1.jpg) 50% 50% /100% auto no-repeat; */
  background: #222;
  color: #FFF;
  user-select: none;
}
section.sec_04{
  background: #f1f1f1;
  position: relative;
  user-select:none;
  padding: 100px 0;
}
.end_text{
  text-align: left;
  font-size: 56px;
  font-weight: 600;
}
.h100{
  height: 100%;
}

.bold{
  font-weight: 600;
}
.mt_minus20{
  margin-top: -20px;
}
.center_box{
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.graphic_wrap{
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.graphic_wrap::before{
  content: "";
  display: block;
  position: absolute;
  width: 240px;
  height: 1px;
  background: rgba(255,255,255,0.4);
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.upAnimate.an1{
  position: relative;
  animation: upAnimate 2s 0s infinite linear;
}
.upAnimate.an2{
  position: relative;
  animation: upAnimate 2s 1s infinite linear;
}
.upAnimate.an3{
}
@keyframes upAnimate {
  0%{
    bottom: 0;
  }
  50%{
    bottom: 10px;
  }
  100%{
    bottom: 0;
  }
}
@keyframes lineAnimate {
  0%{
    margin-left: -115px;
  }
  50%{
    margin-left: 115px;
  }
  100%{
    margin-left: -115px;
  }
}
h2{
  text-align: left;
  font-size: 56px;
  line-height: 82px;
  font-weight: 300;
}
.sub_text{
  text-align: left;
  font-size: 21px;
  font-weight: 200;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
  line-height: 36px;
}
.about_arrow{
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
  font-size: 18px;
}
.about_arrow::before{
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 2px;
  background: #FFF;
  bottom: -30px;
  left: calc(50% - 15px);
  transform-origin: center;
  transform: rotate(60deg);
  animation: arrowA 1s 1s infinite;
}
.about_arrow::after{
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 2px;
  background: #FFF;
  bottom: -30px;
  left: calc(50% - 7.5px);
  transform-origin: center;
  transform: rotate(-60deg);
  animation: arrowA 1s 1s infinite;
}
.introduce_wrap{
  padding: 120px 0 20px;
  margin: 0 auto;
}
.introduce_wrap h2{
  margin-bottom: 120px;
  position: relative;
  font-size: 42px;
  font-weight: 600;
}

.introduce_wrap h3{
  text-align: left;
  font-size: 24px;
  font-weight: 500;
}
.introduce_wrap p.exp{
  margin-top: 25px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 25px;
}
.skills_wrap{
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 48px;
  padding-bottom: 40px;
}
.skills_wrap h2{
  font-size: 32px;
  font-weight: 600;
}
.skill_list{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;  
}
.mt40{
  
}
.intro_flex{
  display: flex;
  gap: 60px;
}
.intro_flex .list{
  flex: 1;
  position: relative;
}
.intro_flex .list h3{
  font-size: 24px;
}
.intro_flex .list p.exp{
  font-size: 18px;
  line-height: 26px;
  text-align: left;
}
.intro_flex .list::before{
  content: "";
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  background: #111;
  border-radius: 50%;
  top: -46px;
}
.project_wrap{
  padding: 120px 0 120px;
}
.prject_list{
}
.prject_list li{
  width: 100%;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  color: #fff;
  padding: 50px 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
}
.prject_list li .number{
  font-size: 34px;
  width: 120px;
  flex-shrink: 0;
}
.prject_list li h3{
  width: 360px;
  flex-shrink: 0;
}
.prject_list li p{
  width: 100%;
  font-size: 24px;
  font-weight: 300;
}
.prject_list li .seeMore{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #FFF;
  flex-shrink: 0;
  font-size: 30px;
  font-weight: 200;
  padding-bottom: 4px;
  margin-left: 40px;
  transition: all 0.4s;
}
.prject_list li:hover .seeMore{
  background: rgba(255,255,255,0.7);
  color: #000;
}
.prject_list li h3{
  font-size: 28px;
  line-height: 34px;
}
.prject_list li p{
  font-size: 20px;
  padding-top: 10px;
  line-height: 28px;
}
.project-inner{
  margin-top: 100px;
  border-top: 1px solid #FFF;
}
.popup{
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  background: rgba(0,0,0,0.5);
}
.popup .inner{
  position: absolute;
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  background: #FFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 20px;
  padding: 30px 20px 30px 30px;
  
}
.popup .inner .scroll{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-right: 10px;
}
.popup .inner .img{
  margin-top: 40px;
  width: 100%;
  min-height: calc(100% - 400px);
}
.popup .inner .img .slick-slide img{
  width: 100%;
  height: 100%;
}
.popup .inner .img .slick-next{
  right: 10px;
  z-index: 5;
}
.popup .inner .img .slick-prev{
  left: 10px;
  z-index: 5;
}
.popup .inner .title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 42px;
  font-weight: 600;
}
.popup .inner .subTitle{
  font-size: 30px;
  font-weight: 500;
  margin-top: 20px;
}
.popup .inner .text{
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
  font-weight: 400;
}
.popup .inner .popupClose{
  position: relative;
  font-size: 24px;
  cursor: pointer;
}
.popup .inner .slick-dots{
  position: relative;
  bottom: 0;
}
.popup .inner .button.Link{
  cursor: pointer;
  display: block;
  width: 140px;
  margin: 0 auto;
  height: 40px;
  background: #0e174d;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  color: #FFF;
}
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@keyframes arrowA {
  0%,100%{
    bottom: -30px;
  }
  50%{
    bottom: -35px;
  }
}
@media screen and (max-width:1524px) {
  section.sec_02::before{
    display: none;
  }
  .graphic_wrap{
    display: block;
  }
  .graphic_wrap::before{
    display: none;
  }
  .prject_list li h3{
    font-size: 18px;
    width: 240px;
  }
  .prject_list li p{
    font-size: 16px;
    line-height: 22px;
  }
  .prject_list li .number {
    font-size: 20px;
    width: 80px;
    flex-shrink: 0;
  }
  .end_text{
    font-size: 38px;
  }
  h2{
    font-size: 38px;
    line-height: 52px;
  }
  .sub_text{
    margin-top: 20px;
  }
}
@media screen and (min-width:1524px) {
  .graphic_wrap::after{
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 1px;
    background: #FFF;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: lineAnimate 7s 0s infinite;
  }
}
@media screen and (max-width:1024px) {
  section.sec_01{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
  }
  .introduce_wrap h2{
    font-size: 28px;
  }
  .intro_flex .list::before{
    display: none;
  }
  section.sec_02::before{
    display: none;
  }
  .intro_flex{
    display: block;
  }
  .prject_list li{
    display: block;
    position: relative;
  }
  .prject_list li .seeMore{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .end_text{
    font-size: 30px;
  }
  h2{
    font-size: 24px;
    line-height: 36px;
  }
  .sub_text{
    font-size: 18px;
    margin-top: 20px;
  }
  .container{
    width: 100%;
  }
}